@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200..900);
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'WorkSans',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Regular';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Light';
  src: url(/static/media/NotoSans-Light.2cfb54d9.ttf);
}

@font-face {
  font-family: 'Regular';
  src: url(/static/media/NotoSans-Regular.eae34fa8.ttf);
}

@font-face {
  font-family: 'Medium';
  src: url(/static/media/NotoSans-Medium.a4c42b7a.ttf);
}

@font-face {
  font-family: 'Heavy';
  src: url(/static/media/NotoSans-Bold.23e70cfb.ttf);
}

.jodit-container,
.jodit-container:not(.jodit_inline) {
  border: none;
}

.jodit-toolbar__box,
.jodit-toolbar__box:not(:empty) {
  background-color: #ffffff;
  color: #000000;
  border: none;
}

.jodit-toolbar-editor-collection_mode_horizontal {
  background-image: none !important;
}

.jodit-workplace {
  border-radius: 8px;
  border: 1px solid #d5d7da !important;
  border: 1px solid var(--Gray-300, #d5d7da) !important;
}

.jodit-status-bar {
  background-color: #ffffff;
  color: #000000;
}

.jodit-ui-group::after {
  display: none;
}

