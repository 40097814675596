.jodit-container,
.jodit-container:not(.jodit_inline) {
  border: none;
}

.jodit-toolbar__box,
.jodit-toolbar__box:not(:empty) {
  background-color: #ffffff;
  color: #000000;
  border: none;
}

.jodit-toolbar-editor-collection_mode_horizontal {
  background-image: none !important;
}

.jodit-workplace {
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d5d7da) !important;
}

.jodit-status-bar {
  background-color: #ffffff;
  color: #000000;
}

.jodit-ui-group::after {
  display: none;
}
